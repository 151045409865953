import Toaster from 'toastr';

Toaster.options = {
  positionClass: 'toast-top-full-width',
  closeButton: false,
  preventDuplicates: true,
  hideDuration: 2000,
  timeOut: 1000,
};

export default Toaster;
